import React from "react"
import Img from "gatsby-image"

class TeamMember extends React.Component {
  render() {

    const {title, name, imgURL, text} = this.props;

    return (
      <div class="content">
        <h1 class="title is-4 has-text-primary has-text-weight-light">{name}</h1>
        <h2 class="subtitle is-5 has-text-black has-text-weight-normal">{title}</h2>
        <br/>

        <div class="columns">

        <div class="column is-one-third">
            <Img style={{borderRadius: "50%", maxWidth: "400px", display: "block", marginLeft: "auto", marginRight: "auto"}} fluid={imgURL} alt=''/>
         </div>
          <div class="column" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <h4 class="subtitle has-text-grey has-text-weight-light">
            <div dangerouslySetInnerHTML={{ __html: text}}/>
          </h4>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamMember
