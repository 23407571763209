import React from "react"
import TeamMembersList from "./teamMembersList"
import { StaticQuery, graphql } from "gatsby"

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulTcStatic(contentful_id: {eq: "26yBSHL6SxakT5D6TbaOKT"}) {
          titleString
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `
    }
    render = {data => (

      <section class="section">
        <div class="container is-fluid wrapper standard-margin">
        <br/>
        <br/>
        <h1 class="title has-text-primary has-text-weight-light">{data.contentfulTcStatic.titleString}</h1>
        <h2 class="subtitle has-text-weight-light">
        <div dangerouslySetInnerHTML={{ __html: data.contentfulTcStatic.textContent.childMarkdownRemark.html}}/>
        </h2>
        <hr class="hr"/>

        <TeamMembersList/>

        <br/>
        <br/>

        </div>
      </section>

    )}
    />
    )

export default About
