import React from "react"
import TeamMember from "../About/teamMember"
import { StaticQuery, graphql } from "gatsby"

const TeamMembersList = () => (
  <StaticQuery
    query={graphql`
      query {

        allContentfulTcTeam(sort: {fields: order}) {
          edges {
            node {
              id
              fullName
              position
              description {
                childMarkdownRemark {
                  html
                  rawMarkdownBody
                }
              }
              profilePicture {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
    }
    `
    }
    render = {data => (

      <div>
        {data.allContentfulTcTeam.edges.map(({node}) => (
          <div>
            <br/><br/>

            <TeamMember name={node.fullName}
            title={node.position}
            text={node.description.childMarkdownRemark.html}
            imgURL={node.profilePicture.fluid}

            />

            <hr class="hr"/>
          </div>
        ))}

      </div>


      )}
      />
      )

export default TeamMembersList
