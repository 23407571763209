import React from "react"
import Layout from "../components/Layout/layout"
import About from "../components/About/about"
import Maps from "../components/About/maps"

const SecondPage = () => (
  <Layout slug = 'Über uns'>
    <About/>
    <Maps/>
  </Layout>
)

export default SecondPage
